import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import * as MuiPickers from '@material-ui/pickers';
import React from 'react';

import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { useSubscriptionEditDialog } from './SubscriptionEditDialog.hook';
import useStyles from './SubscriptionEditDialog.styles';
import { SubscriptionUtils } from 'helpers';

function SubscriptionEditDialog() {
  const classes = useStyles();

  const {
    formState,
    subscriptionState,
    handleChangeForm,
    handleDialogClose,
    handleDateChange,
    handleSubmit,
    hasError,
  } = useSubscriptionEditDialog();

  console.log(formState.values.expiration_date);

  return (
    <React.Fragment>
      <Mui.AppBar className={classes.appBar}>
        <Mui.Toolbar>
          <Mui.IconButton
            aria-label="Fechar"
            color="inherit"
            edge="start"
            onClick={handleDialogClose}
          >
            <MuiIcons.Close />
          </Mui.IconButton>
          <Mui.Typography className={classes.title} variant="h5">
            {subscriptionState === 'create'
              ? 'Criar nova assinatura'
              : 'Editar assinatura'}
          </Mui.Typography>
          <Mui.Button color="inherit" onClick={handleSubmit}>
            {subscriptionState === 'create' ? 'Criar' : 'Salvar'}
          </Mui.Button>
        </Mui.Toolbar>
      </Mui.AppBar>
      <Mui.Grid className={classes.container} container spacing={2}>
        <Mui.Grid item xs={12}>
          <Mui.Typography variant="h5">Dados da assinatura</Mui.Typography>
        </Mui.Grid>
        <Mui.Grid item md={4} sm={6} xs={12}>
          <Mui.TextField
            InputLabelProps={{
              classes: {
                disabled: classes.selectDisabled,
              },
            }}
            InputProps={{
              classes: {
                disabled: classes.selectDisabled,
              },
            }}
            disabled
            error={hasError('amount')}
            fullWidth
            helperText={hasError('amount') ? formState.errors.amount : ''}
            id="amount"
            label="Valor"
            name="amount"
            onChange={handleChangeForm}
            type="tel"
            value={formState.values.amount || 'Não informado'}
            variant="outlined"
          />
        </Mui.Grid>
        <Mui.Grid item md={4} sm={6} xs={12}>
          <Mui.TextField
            InputLabelProps={{
              classes: {
                disabled: classes.selectDisabled,
              },
            }}
            InputProps={{
              classes: {
                disabled: classes.selectDisabled,
              },
            }}
            disabled
            error={hasError('description')}
            fullWidth
            helperText={hasError('description') && formState.errors.description}
            id="description"
            label="Descrição"
            name="description"
            onChange={handleChangeForm}
            value={formState.values.description || 'Não informado'}
            variant="outlined"
          />
        </Mui.Grid>
        <Mui.Grid item md={4} sm={6} xs={12}>
          <Mui.TextField
            InputLabelProps={{
              classes: {
                disabled: classes.selectDisabled,
              },
            }}
            InputProps={{
              classes: {
                disabled: classes.selectDisabled,
              },
            }}
            disabled
            error={hasError('status')}
            fullWidth
            helperText={hasError('status') && formState.errors.status}
            id="status"
            label="Status"
            name="status"
            onChange={handleChangeForm}
            value={
              SubscriptionUtils.getStatusLabel(formState.values.status) || ''
            }
            variant="outlined"
          />
        </Mui.Grid>

        <Mui.Grid item md={4} sm={6} xs={12}>
          <MuiPickers.MuiPickersUtilsProvider
            libInstance={moment}
            locale="pt-br"
            utils={MomentUtils}
          >
            <MuiPickers.DatePicker
              DialogProps={{
                PaperProps: {
                  classes: {
                    root: classes.rootDialog,
                  },
                },
              }}
              cancelLabel="CANCELAR"
              clearLabel="LIMPAR"
              clearable
              format="DD/MM/YYYY"
              fullWidth
              id="due_date"
              inputVariant="outlined"
              label="Data da próxima cobrança"
              leftArrowButtonProps={{
                classes: {
                  root: classes.buttonPicker,
                },
              }}
              name="due_date"
              onChange={handleDateChange('due_date')}
              rightArrowButtonProps={{
                classes: {
                  root: classes.buttonPicker,
                },
              }}
              value={formState.values.due_date}
            />
          </MuiPickers.MuiPickersUtilsProvider>
        </Mui.Grid>

        <Mui.Grid item md={4} sm={6} xs={12}>
          <MuiPickers.MuiPickersUtilsProvider
            libInstance={moment}
            locale="pt-br"
            utils={MomentUtils}
          >
            <MuiPickers.DatePicker
              DialogProps={{
                PaperProps: {
                  classes: {
                    root: classes.rootDialog,
                  },
                },
              }}
              InputLabelProps={{
                classes: {
                  disabled: classes.selectDisabled,
                },
              }}
              InputProps={{
                classes: {
                  disabled: classes.selectDisabled,
                },
              }}
              cancelLabel="CANCELAR"
              clearLabel="LIMPAR"
              clearable
              disabled
              format="DD/MM/YYYY"
              fullWidth
              id="expiration_date"
              inputVariant="outlined"
              label="Data de expiração"
              leftArrowButtonProps={{
                classes: {
                  root: classes.buttonPicker,
                },
              }}
              name="expiration_date"
              onChange={handleDateChange('expiration_date')}
              rightArrowButtonProps={{
                classes: {
                  root: classes.buttonPicker,
                },
              }}
              value={formState.values.expiration_date}
            />
          </MuiPickers.MuiPickersUtilsProvider>
        </Mui.Grid>

        <Mui.Grid item md={4} sm={6} xs={12}>
          <MuiPickers.MuiPickersUtilsProvider
            libInstance={moment}
            locale="pt-br"
            utils={MomentUtils}
          >
            <MuiPickers.DatePicker
              DialogProps={{
                PaperProps: {
                  classes: {
                    root: classes.rootDialog,
                  },
                },
              }}
              InputLabelProps={{
                classes: {
                  disabled: classes.selectDisabled,
                },
              }}
              InputProps={{
                classes: {
                  disabled: classes.selectDisabled,
                },
              }}
              cancelLabel="CANCELAR"
              clearLabel="LIMPAR"
              clearable
              disabled
              format="DD/MM/YYYY"
              fullWidth
              id="suspended_at"
              inputVariant="outlined"
              label="Data da suspensão"
              leftArrowButtonProps={{
                classes: {
                  root: classes.buttonPicker,
                },
              }}
              name="suspended_at"
              onChange={handleDateChange('suspended_at')}
              rightArrowButtonProps={{
                classes: {
                  root: classes.buttonPicker,
                },
              }}
              value={formState.values.suspended_at}
            />
          </MuiPickers.MuiPickersUtilsProvider>
        </Mui.Grid>

        {/* <Mui.Grid item xs={12}>
          <Mui.Divider />
        </Mui.Grid>
        <Mui.Grid item xs={12}>
          <Mui.Typography variant="h4">Metadados</Mui.Typography>
        </Mui.Grid>
        <Mui.Grid item lg={8} md={10} xs={12}>
          <Metadata
            metadata={subscription?.metadata}
            onChange={handleMetadataChange}
          />
        </Mui.Grid> */}
      </Mui.Grid>
    </React.Fragment>
  );
}

export default SubscriptionEditDialog;
